import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios';

Vue.config.productionTip = false

import '@/sass/style.sass'

let http = axios.create({
  https: false,
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const encodedToken = window.btoa(`${process.env.VUE_APP_AUTH_USER}:${process.env.VUE_APP_AUTH_PASSWORD}`);

http.interceptors.request.use(function (config) {
  const token = 'Basic ' + encodedToken;
  config.headers.Authorization =  token;
  return config;
});

Vue.prototype.$http = http

new Vue({
  vuetify,
  http,
  render: h => h(App)
}).$mount('#app')
