<template>
  <v-container fluid class="mx-8">
    <v-row>
      <v-col cols="9" class="pr-15">
        <div class="d-flex justify-center align-center">
          <img :src="require('@/assets/symbole-bleu.png')" style="height: 130px" class="mr-4" />
          <div class="alexander h1-title">Hébergement</div>
        </div>
        <p class="text-center my-10">
          Un château datant du 16ème siècle empreint d'histoire fascinante, près d'un petit village au coeur du Pays
d'Auge: ressourcez-vous au château du Breuil.
        </p>
        <div class="mx-auto relative">
          <div style="width: 85%; position: absolute; right: 0; height: 35%; border: 5px solid lightgrey" />
          <div style="width: 40%; position: absolute; bottom: 0; height: 80%; border: 5px solid #dcb946;" />
          <v-img
            :src="require('@/assets/photo-fenetre.jpg')"
            contain
            class="mx-auto py-8 pl-8"
            width="90%"
          >
            <div style="width: 40%; color: white; height: 100%" class="d-flex px-10 justify-center align-center">
              <p class="text-center mx-auto">Accès à l'une des tours comprenant: 1 Suite Junior et 4 chambres faces au parc fleuri, situé entre Deauville et Lisieux. A 24 km d'Honfleur, 200 km de Paris, venir au Château du Breuil est la garantie d'un séjour ressourçant et dépaysant.</p>
            </div>
          </v-img>
        </div>
        <p class="mt-10">
          Nous vous recevons dans la tour Nord privatisée du château pour un séjour au calme.
        </p>
        <p>
          Vous y retrouverez 1 Suite Junior et 4 chambres pouvant accueillir entre 2 et 10 personnes.
        </p>
        <p>
          Entre amis ou en famille mais également lors d'une escapade en amoureux, promenez-vous dans le parc du
          domaine et profitez d'un moment hors du temps lors d'une visite guidée où notre procesus de création de nos
          spiritueux vous sera dévoilé. Pour un séjour normand typique au sein du Pays d'Auge, un cocktail de bienvenue
          vous sera offert à votre arrivée ainsi que d'une bouteille de cidre.
        </p>
        <div class="d-flex my-10 justify-center">
          <img :src="require('@/assets/symbole-bleu.png')" style="width: 5vw" />
        </div>
        <p>
          Vous aurez un accès au salon, un endroit où se lover devant la télévision accompagné d’un verre de cidre et
          à la cuisine où vous pourrez trouver tout le nécessaire pour un petit-déjeuner, déjeuner ou un dîner. La cuisine
          est équipée entièrement (four, lave-vaisselle, cave à vin, bouilloire, nespresso, réfrigérateur, micro-onde,
          four et plaque à induction).
        </p>
        <v-row class="my-10">
          <v-col cols="3">
            <div class="alexander h2-title">Équipements</div>
            <p>Wifi Gratuit</p>
            <p>Vaisselles</p>
            <p>Parking</p>
            <p>Draps et linge de maison fournis</p>
          </v-col>
          <v-col cols="9" class="relative">
            <div style="width: 85%; position: absolute; top: 10%; height: 35%; border: 3px solid lightgrey" />
            <div style="width: 85%; position: absolute; top: 20%; right: 0; height: 50%; border: 5px solid #dcb946" />
            <div style="width: 35%; position: absolute; bottom: 0; right: 5%; height: 50%; border: 5px solid lightgrey" />
            <v-img style="position: absolute; left: 3%; bottom: 3%" :src="require('@/assets/cuisine.jpg')" width="55%" contain />
            <v-img style="position: absolute; right: 2%" :src="require('@/assets/salon.jpg')" width="55%" contain />
          </v-col>
        </v-row>
        <div class="d-flex justify-center align-center">
          <img :src="require('@/assets/symbole-bleu.png')" style="height: 130px" class="mr-4" />
          <div class="alexander h1-title my-10">Nos chambres</div>
        </div>
        <p>
          Nos chambres prestigieuses, au style campagne moderne et chic, offrent un cadre intimiste et chaleureux,
          idéal pour vos moments entres amis ou en famille.
        </p>
        <p>
          Revêtue de boiserie crème et de matériaux nobles, notre Suite Junior au style classique-chic vous apportera
          le confort et la quiétude. Un espace intimiste côté chambre et petit salon. Côté mobilier, vous retrouverez des
          objets modernes, aux couleurs plus affirmées pour refléter le caractère de la nature environnante. Le tout au
          sein même d'une distillerie en activité.
        </p>
        <!-- Long Bois Suite Junior -->
        <div class="d-flex my-10">
          <div class="d-flex relative align-center">
            <div style="width: 85%; position: absolute; top: 0; height: 60%; border: 5px solid lightgrey" />
            <div style="width: 85%; position: absolute; bottom: 0; right: 0; height: 75%; border: 5px solid #dcb946" />
            <img :src="require('@/assets/suite-junior-1.jpg')" class="my-4 mx-4" style="z-index: 10; width: 15vw" />
            <img :src="require('@/assets/suite-junior-2.jpg')" class="mr-4 my-4" style="z-index: 10; width: 15vw" />
          </div>
          <div class="ml-8 d-flex flex-column justify-center">
            <div class="text-center alexander h3-title">
              Long Bois Suite Junior
            </div>
            <p class="text-center">
              Vivez en plein lumière au gré des saisons
            </p>
            <v-row>
              <v-col cols="6">
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-lit.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>Lit 2 places</p>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-vue.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>Vue parc fleuri</p>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-pax.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>2 personnes</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-m2.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>38m²</p>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-douche.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>Salle de douche</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="d-flex my-10">
          <div class="mr-8 d-flex flex-column justify-center" style="flex: 1">
            <div class="text-center h3-title">
              <span class="alexander">Prix</span> 350€ TTC
            </div>
            <p class="text-center">
              Accès salon, cuisine et « Long Bois Suite junior »
            </p>
          </div>
          <div class="d-flex relative align-center" style="flex: 1">
            <div style="width: 85%; position: absolute; bottom: 0; height: 60%; border: 5px solid lightgrey" />
            <div style="width: 85%; position: absolute; top: 0; right: 0; height: 75%; border: 5px solid #dcb946" />
            <img :src="require('@/assets/suite-junior-3.jpg')" class="my-4 mx-4" style="z-index: 10; width: 45%" />
            <img :src="require('@/assets/suite-junior-4.jpg')" class="mr-4 my-4" style="z-index: 10; width: 45%" />
          </div>
        </div>
        <!-- Joly Rouge -->
        <div class="d-flex my-10">
          <div class="d-flex relative align-center">
            <div style="width: 85%; position: absolute; top: 0; height: 60%; border: 5px solid lightgrey" />
            <div style="width: 85%; position: absolute; bottom: 0; right: 0; height: 75%; border: 5px solid #dcb946" />
            <img :src="require('@/assets/joly-rouge-1.jpg')" class="my-4 mx-4" style="z-index: 10; width: 33vw" />
          </div>
          <div class="ml-8 d-flex flex-column justify-center">
            <div class="text-center alexander h2-title">
              Joly Rouge
            </div>
            <v-row style="flex-grow: 0">
              <v-col cols="6">
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-lit.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>Lit 2 places</p>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-pax.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>4 personnes</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-douche.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>Salle de douche</p>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-m2.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>Vue parc fleuri et cour du château</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="d-flex my-10">
          <div class="mr-8 d-flex flex-column justify-center" style="flex: 1">
            <div class="text-center h3-title">
              <span class="alexander">Prix</span> 450€ TTC
            </div>
            <p class="text-center">
              Accès salon, cuisine, « Long Bois Suite junior » et la chambre Joly Rouge
            </p>
          </div>
          <div class="d-flex relative align-center" style="flex: 1">
            <div style="width: 85%; position: absolute; bottom: 0; height: 60%; border: 5px solid lightgrey" />
            <div style="width: 85%; position: absolute; top: 0; right: 0; height: 75%; border: 5px solid #dcb946" />
            <img :src="require('@/assets/joly-rouge-2.jpg')" class="my-4 mx-4" style="z-index: 10; width: 100%" />
          </div>
        </div>
        <!-- Pépin Dorée -->
        <div class="d-flex my-10">
          <div class="d-flex relative align-center">
            <div style="width: 85%; position: absolute; top: 0; height: 60%; border: 5px solid lightgrey" />
            <div style="width: 85%; position: absolute; bottom: 0; right: 0; height: 75%; border: 5px solid #dcb946" />
            <img :src="require('@/assets/pepin-doree-1.jpg')" class="my-4 mx-4" style="z-index: 10; width: 32vw" />
          </div>
          <div class="ml-8 d-flex flex-column justify-center">
            <div class="text-center alexander h2-title">
              Pépin dorée
            </div>
            <v-row style="flex-grow: 0">
              <v-col cols="6">
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-lit.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>Lit 2 places</p>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-m2.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>32m²</p>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-pax.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>6 personnes</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-douche.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>Salle de douche</p>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-m2.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>Vue parc fleuri et cour du château</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="d-flex my-10">
          <div class="mr-8 d-flex flex-column justify-center" style="flex: 1">
            <div class="text-center h3-title">
              <span class="alexander">Prix</span> 550€ TTC
            </div>
            <p class="text-center">
              Accès salon, cuisine, « Long Bois Suite junior », la chambre « Joly Rouge » et la chambre « Pépin Dorée »
            </p>
          </div>
          <div class="d-flex relative align-center" style="flex: 1">
            <div style="width: 85%; position: absolute; bottom: 0; height: 60%; border: 5px solid lightgrey" />
            <div style="width: 85%; position: absolute; top: 0; right: 0; height: 75%; border: 5px solid #dcb946" />
            <img :src="require('@/assets/pepin-doree-2.jpg')" class="my-4 mx-4" style="z-index: 10; width: 100%" />
          </div>
        </div>
        <!-- Pomme de Cheval et Pomme de Suie -->
        <div class="d-flex my-10">
          <div class="d-flex relative align-center">
            <div style="width: 85%; position: absolute; top: 0; height: 60%; border: 5px solid lightgrey" />
            <div style="width: 85%; position: absolute; bottom: 0; right: 0; height: 75%; border: 5px solid #dcb946" />
            <img :src="require('@/assets/pomme-cheval-1.jpg')" class="my-4 mx-4" style="z-index: 10; width: 32vw" />
          </div>
          <div class="ml-8 d-flex flex-column justify-center">
            <div class="text-center alexander h3-title">
              Pomme de Cheval et Pomme de Suie
            </div>
            <v-row style="flex-grow: 0">
              <v-col cols="6">
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-lit.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>2 lits 2 places</p>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-m2.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>20 et 25m²</p>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-pax.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>10 personnes</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-douche.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>Salle de douche</p>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="4" class="d-flex align-center">
                    <v-img :src="require('@/assets/icone-m2.png')" class="mb-3" width="100%" contain />
                  </v-col>
                  <v-col cols="8">
                    <p>Vue parc fleuri et cour du château</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="d-flex my-10">
          <div class="mr-8 d-flex flex-column justify-center" style="flex: 1">
            <div class="text-center h3-title">
              <span class="alexander">Prix</span> 650€ TTC
            </div>
            <p class="text-center">
              Accès à la tour complète
            </p>
          </div>
          <div class="d-flex relative align-center" style="flex: 1">
            <div style="width: 85%; position: absolute; bottom: 0; height: 60%; border: 5px solid lightgrey" />
            <div style="width: 85%; position: absolute; top: 0; right: 0; height: 75%; border: 5px solid #dcb946" />
            <img :src="require('@/assets/pomme-cheval-2.jpg')" class="my-4 mx-4" style="z-index: 10; width: 100%" />
          </div>
        </div>
      </v-col>
      <v-col cols="3" class="relative">
        <v-card class="sticky d-flex flex-column" max-height="calc(100vh - 100px)" min-width="25%" max-width="25%" width="25%">
          <v-card-title style="background-color: #DCB946; color: white; flex: 0">
            Réserver
          </v-card-title>
          <v-card-text class="py-4" style="flex: 1; overflow-y: scroll">
            <div class="text-h6 mb-4">Dates du séjour</div>
            <small v-if="error.includes('dates')" class="error--text">Merci de sélectionner au moins une nuit</small>
            <v-date-picker
              range
              :allowed-dates="allowedDates"
              v-model="dateRange"
              locale="fr"
              first-day-of-week="1"
              full-width
              color="#1e2f53"
              no-title
              elevation="1"
              class="mb-6"
              :min="moment().format('YYYY-MM-DD')"
              @update:picker-date="changed"
            />
            <div class="text-h6" :class="error.includes('info') ? 'mb-0' : 'mb-4'">Formule Chambres*</div>
            <div v-if="error.includes('rate')" class="mb-4">
              <small class="error--text">Veuillez sélectionner une formule</small>
            </div>
            <v-radio-group v-model="rate">
              <v-radio color="#1e2f53" value="junior" label="Long Bois Suite Junior - 2 personnes (350€/jour)" />
              <v-radio color="#1e2f53" value="jolyrouge" label="Joly Rouge - 4 personnes (450€/jour)" />
              <v-radio color="#1e2f53" value="pepindoree" label="Pépin Dorée - 6 personnes (550€/jour)" />
              <v-radio color="#1e2f53" value="pommecheval" label="Pomme de Cheval et Pomme de Suie - 10 personnes (650€/jour)" />
            </v-radio-group>
            <div class="text-h6" :class="error.includes('info') ? 'mb-0' : 'mb-4'">Coordonnées*</div>
            <div v-if="error.includes('info')" class="mb-4">
              <small class="error--text">Merci de remplir tous les champs</small>
            </div>
            <v-text-field
              color="#1e2f53"
              outlined
              dense
              v-model="name"
              label="Nom*"
            />
            <v-text-field
              outlined
              color="#1e2f53"
              dense
              v-model="firstName"
              label="Prénom*"
            />
            <v-text-field
              outlined
              color="#1e2f53"
              dense
              v-model="email"
              label="Email*"
            />
            <v-text-field
              outlined
              color="#1e2f53"
              dense
              v-model="phone"
              label="Téléphone*"
            />
            <v-text-field
              outlined
              color="#1e2f53"
              dense
              v-model="address"
              label="Adresse*"
            />
            <v-text-field
              outlined
              color="#1e2f53"
              dense
              v-model="zipCode"
              label="Code postal*"
            />
            <v-text-field
              outlined
              color="#1e2f53"
              dense
              v-model="city"
              label="Ville*"
            />
            <v-text-field
              outlined
              color="#1e2f53"
              dense
              v-model="country"
              label="Pays*"
            />
            <div class="text-h6" :class="error.includes('info') ? 'mb-0' : 'mb-4'">Participants*</div>
            <div v-if="error.includes('pax')" class="mb-4">
              <small class="error--text">Merci de saisir au moins 1 participant.</small>
            </div>
            <div v-if="error.includes('limit')" class="mb-4">
              <small class="error--text">Trop de participants pour les chambres sélectionnées.</small>
            </div>
            <v-text-field
              outlined
              dense
              color="#1e2f53"
              v-model="adultPax"
              label="Adultes"
            />
            <v-text-field
              outlined
              dense
              color="#1e2f53"
              v-model="childrenPax"
              label="Enfants"
              persistent-hint
              hint="Ayant moins de 18 ans à la date du séjour"
            />
            <v-text-field
              outlined
              color="#1e2f53"
              dense
              v-model="petsPax"
              label="Animaux"
              persistent-hint
              hint="Supplément de 20€ par jour par animal"
            />
            <div class="text-h6 my-4">Notes</div>
            <v-textarea
              v-model="guestNotes"
              rows="5"
              color="#1e2f53"
              outlined
              placeholder="Indiquez vos éventuelles remarques ou demandes spécifiques."
            />
          </v-card-text>
          <v-card-actions class="d-flex" style="flex: 0">
            <div>
              <div>
                <b>Total: {{ total }}€</b> <span v-if="totalDays">(pour {{ totalDays }} nuit{{ totalDays > 1 ? 's' : '' }})</span>
              </div>
              <small>
                dont {{ (taxeSejour * totalDays).toFixed(2) }}€ de taxe de séjour
              </small>
            </div>
            <v-spacer />
            <div>
              <v-btn dark color="#dcb946" @click="submit" :loading="loading" :disabled="loading">
                RÉSERVER
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="sent" width="50vw">
      <v-card width="50vw" min-height="50vh" class="d-flex flex-column">
        <v-card-title class="white--text" style="background-color: #dcb946; flex: 0">
          Merci !
        </v-card-title>
        <v-card-text class="d-flex flex-column justify-center" style="flex: 1">
            <p>Votre demande de réservation a bien été prise en compte !</p>
            <p class="my-10">Vous recevrez sous peu une confirmation par mail.</p>
            <p style="line-height: 2rem">Si vous ne la recevez pas, ou que vous avec la moindre question, contactez nous au 02.31.65.60.09 ou par mail à floriane.rubiard@chateau-breuil.fr</p>
            <div class="mx-auto mt-10">
              <v-btn color="#1e2f53" dark href="https://chateau-breuil.com" class="white--text">
                <v-icon class="mr-3">mdi-arrow-left</v-icon>
                RETOUR AU SITE CHÂTEAU DU BREUIL
              </v-btn>
            </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment';
  export default {
    data: () => ({
      loading: false,
      moment,
      name: null,
      firstName: null,
      address: null,
      zipCode: null,
      city: null,
      country: null,
      adultPax: 0,
      childrenPax: 0,
      petsPax: 0,
      rate: null,
      guestNotes: null,
      start: moment().format('YYYY-MM-DD'),
      end: moment().add(1, 'days').format('YYYY-MM-DD'),
      email: null,
      phone: null,
      bookedDates: [],
      error: [],
      sent: false
    }),
    created(){
      this.getBookings();
    },
    computed: {
      totalDays(){
        return moment(this.end).diff(moment(this.start), 'days') ? moment(this.end).diff(moment(this.start), 'days') : 0;
      },
      total(){
        let total = 0;
        if(this.rate === 'junior'){
          total += 350;
        }
        if(this.rate === 'jolyrouge'){
          total += 450;
        }
        if(this.rate === 'pepindoree'){
          total += 550
        }
        if(this.rate === 'pommecheval'){
          total += 650
        }
        if(this.petsPax){
          total += 20 * this.petsPax;
        }
        total += this.taxeSejour;
        return ( total * this.totalDays ).toFixed(2);
      },
      allowedPax(){
        if(this.rate === 'junior'){
          return 2
        }
        if(this.rate === 'jolyrouge'){
          return 4
        }
        if(this.rate === 'pepindoree'){
          return 6
        }
        if(this.rate === 'pommecheval'){
          return 10
        }
        return 0;
      },
      taxeSejour(){
        let taxe = 0;
        if(this.adultPax){
          taxe += this.adultPax * 2.3;
        }
        return taxe;
      },
      dateRange: {
        get(){
          if(this.start && this.end){
            return [this.start, this.end];
          }
          if(this.start){
            return [this.start];
          }
          if(this.end){
            return [this.end]
          }
          return [];
        },
        set(val){
          this.start = val[0];
          this.end = val[1] ? val[1] : null;
          if(moment(this.end).isBefore(moment(this.start))){
            let end = this.end;
            this.end = this.start;
            this.start = end;
          }
          this.bookedDates.forEach(bookedDate => {
            if(moment(bookedDate).isBetween(moment(this.start), moment(this.end)) && this.start !== this.end){
              this.end = this.start;
            }
          })
        }
      }
    },
    methods: {
      changed(val){
        this.getBookings(val);
      },
      getBookings(date = moment()){
        this.$http.get(`hotel-rooms?date=${moment(date).subtract(1, 'months').format('YYYY-MM-01')}`).then(res => {
        let bookedDates = [];
        res.data.result.forEach(booking => {
          let currentDate = moment(booking.start);
          while(moment(currentDate).isBefore(moment(booking.end))){
            bookedDates.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'days');
          }
        })
        this.bookedDates = bookedDates;
        if(this.bookedDates.includes(this.start)){
          this.start = null;
        }
      });
      },
      allowedDates(val){
        return !this.bookedDates.includes(val);
      },
      submit(){
        this.loading = true;
        this.error = [];
        if(!this.start || !this.end || this.start === this.end){
          this.error.push('dates');
        }
        if(!this.rate){
          this.error.push('rate');
        }
        if(!this.adultPax && !this.childrenPax){
          this.error.push('pax');
        }
        if(!this.address || !this.address.length || !this.zipCode || !this.zipCode.length || !this.city || !this.city.length || !this.country || !this.country.length || !this.name || !this.name.length || !this.firstName || !this.firstName.length || !this.phone || !this.phone.length || !this.email || !this.email.length){
          this.error.push('info');
        }
        if((this.adultPax ? parseInt(this.adultPax,10) : 0) + (this.childrenPax ? parseInt(this.childrenPax,10) : 0) > this.allowedPax){
          this.error.push('limit');
        }
        if(!this.error.length){
          this.$http.post('hotel-rooms', {
            start: this.start,
            end: this.end,
            name: this.name,
            firstName: this.firstName,
            email: this.email,
            phone: this.phone,
            address: this.address,
            zipCode: this.zipCode,
            city: this.city,
            country: this.country,
            rate: this.rate,
            adultPax: this.adultPax,
            childrenPax: this.childrenPax,
            petsPax: this.petsPax,
            source: 'web',
            guestNotes: this.guestNotes,
            confirmed: false
          })
          .then((res) => {this.sent = true; console.log(res);})
          .finally(() => this.loading = false);
        } else {
          this.loading = false;
        }
      }
    }
  }
</script>
