<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      height="80px"
      style="z-index: 20"
    >
      <a href="https://chateau-breuil.com">
        <div class="d-flex align-center didact text-uppercase">
          <img
            :src="require('@/assets/logo-cdb.png')"
            style="height: 70px"
          />
  
          <v-icon class="mr-4">mdi-arrow-left</v-icon>
          <span>
            Retour sur le site Château du Breuil
          </span>
        </div>
      </a>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <IntroductionView />
    </v-main>
  </v-app>
</template>

<script>
import IntroductionView from './components/IntroductionView';

export default {
  components: {
    IntroductionView,
  },

  data: () => ({
    //
  }),
};
</script>
